// extracted by mini-css-extract-plugin
export var benefit = "product-module--benefit--a3d0e";
export var benefitContent = "product-module--benefit-content--2c707";
export var benefitDescription = "product-module--benefit-description--52a9d";
export var benefitList = "product-module--benefit-list--bfbe2";
export var benefitListItem = "product-module--benefit-list-item--a15ca";
export var benefitListItemContent = "product-module--benefit-list-item-content--91de8";
export var benefitSubListItem = "product-module--benefit-sub-list-item--c889f";
export var benefitTitle = "product-module--benefit-title--33fdc";
export var checkIcon = "product-module--check-icon--1b95d";
export var chevronRightIcon = "product-module--chevron-right-icon--c3f06";
export var contact = "product-module--contact--ae608";
export var contactContent = "product-module--contact-content--b8ca4";
export var contactDescription = "product-module--contact-description--16a2b";
export var contactForm = "product-module--contact-form--028e8";
export var contactFormButton = "product-module--contact-form-button--8ac54";
export var contactFormButtonSubtext = "product-module--contact-form-button-subtext--b5bdd";
export var contactTitle = "product-module--contact-title--e9573";
export var container = "product-module--container--7dec9";
export var ctaButton = "product-module--cta-button--86400";
export var ctaContainer = "product-module--cta-container--8a878";
export var ctaText = "product-module--cta-text--31c86";
export var formCheckbox = "product-module--form-checkbox--636fb";
export var formGroup = "product-module--form-group--d62f8";
export var formRow = "product-module--form-row--3075c";
export var intro = "product-module--intro--4c254";
export var introContent = "product-module--intro-content--63a7f";
export var introDescription = "product-module--intro-description--cbe57";
export var introImage = "product-module--intro-image--694ef";
export var introPrice = "product-module--intro-price--2285c";
export var mediaObject = "product-module--media-object--3eecb";
export var mediaObjectContent = "product-module--media-object-content--ddb45";
export var mediaObjectDescription = "product-module--media-object-description--7ba42";
export var mediaObjectImage = "product-module--media-object-image--0da64";
export var mediaObjectTitle = "product-module--media-object-title--70114";
export var moreInfo = "product-module--more-info--30abf";
export var moreInfoContent = "product-module--more-info-content--4259d";
export var moreInfoDescription = "product-module--more-info-description--93e80";
export var moreInfoForm = "product-module--more-info-form--a6513";
export var moreInfoTitle = "product-module--more-info-title--059c7";
export var priceContainer = "product-module--price-container--5f9e1";
export var priceDescription = "product-module--price-description--3502d";
export var priceExVat = "product-module--price-ex-vat--2f8fa";
export var productMain = "product-module--product-main--2966e";
export var stats = "product-module--stats--43173";
export var statsContent = "product-module--stats-content--1fc5e";
export var statsList = "product-module--stats-list--4e435";
export var statsListItem = "product-module--stats-list-item--61a30";
export var statsListItemLabel = "product-module--stats-list-item-label--adf3f";
export var statsListItemValue = "product-module--stats-list-item-value--55e59";
export var statsText = "product-module--stats-text--4afe7";
export var statsTitle = "product-module--stats-title--1da82";
export var subtitle = "product-module--subtitle--563c2";
export var title = "product-module--title--b5699";