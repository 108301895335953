// extracted by mini-css-extract-plugin
export var buttonWithDividerContainer = "dynamic-form-builder-module--button-with-divider-container--0d8e2";
export var candidateInput = "dynamic-form-builder-module--candidate-input--07ec8";
export var candidateList = "dynamic-form-builder-module--candidate-list--cec05";
export var divider = "dynamic-form-builder-module--divider--67bf5";
export var dividerButton = "dynamic-form-builder-module--divider-button--ec910";
export var dividerButtonIcon = "dynamic-form-builder-module--divider-button-icon--01ad6";
export var dividerButtonWrapper = "dynamic-form-builder-module--divider-button-wrapper--0bf1c";
export var dividerWrapper = "dynamic-form-builder-module--divider-wrapper--f451b";
export var formCheckbox = "dynamic-form-builder-module--form-checkbox--0bcc2";
export var formField = "dynamic-form-builder-module--form-field--8d18a";
export var formFieldError = "dynamic-form-builder-module--form-field-error--7a81c";
export var formGroup = "dynamic-form-builder-module--form-group--480e5";
export var formRow = "dynamic-form-builder-module--form-row--58cda";
export var formSubmit = "dynamic-form-builder-module--form-submit--ba22d";
export var loadingSpinner = "dynamic-form-builder-module--loading-spinner--cf11d";
export var modalAccept = "dynamic-form-builder-module--modal-accept--ae9dd";
export var modalCancel = "dynamic-form-builder-module--modal-cancel--8be55";
export var modalCheckout = "dynamic-form-builder-module--modal-checkout--4f2ed";
export var modalCheckoutItem = "dynamic-form-builder-module--modal-checkout-item--20826";
export var modalCheckoutPrice = "dynamic-form-builder-module--modal-checkout-price--eaafc";
export var modalFooter = "dynamic-form-builder-module--modal-footer--9438b";
export var rotateSpinner = "dynamic-form-builder-module--rotateSpinner--cd399";
export var subtitle = "dynamic-form-builder-module--subtitle--0ba37";
export var thankYouMessage = "dynamic-form-builder-module--thankYouMessage--b5777";